/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

/* prettier-ignore */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
/* prettier-ignore */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
  background-color: var(--secondary-color);
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: var(--main-color-white);
  text-decoration: none;
}

/** Fin reset **/

:root {
  --main-font: "Bebas Neue", sans-serif;
  --secondary-font: "Quicksand", sans-serif;
  --main-color-white: #ffff;
  --secondary-color-bluesky: #18d9d9;
  --secondary-color: #011826;
}

strong {
  font-weight: 600;
}

.icon-galerie {
  z-index: 1;
  position: fixed;
  height: calc(15px + 1vw);
  right: 2.5vw;
  top: 2.5vw;
  cursor: pointer;
  animation-name: rotate;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}
.icon-galerie:hover {
  animation-play-state: running;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon-galerie-variant {
  color: var(--secondary-color);
}

/** Header **/

.menu-standard {
  z-index: 1;
  font-family: var(--main-font);
  font-size: calc(15px + 0.5vw);
  position: fixed;
  margin-top: 2.5vw;
  left: 3.5vw;
  height: 100%;
}

.menu {
  margin-top: -2.5vw;
  font-family: var(--secondary-font);
  font-size: calc(10px + 0.5vw);
  gap: 1vw;
  position: fixed;
  top: 49%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.nav-link:hover {
  transition: 0.2s;
  color: var(--secondary-color-bluesky);
}

.selected_nav {
  color: var(--secondary-color-bluesky);
}

.selected_nav::before {
  content: "";
  position: absolute;
  margin-top: calc(6px + 0.1vw);
  margin-left: -100px;
  width: calc(100px - 1vw);
  height: 1px;
  background-color: var(--secondary-color-bluesky);
}

.active {
  color: var(--secondary-color-bluesky);
}

.active::before {
  content: "";
  position: absolute;
  margin-top: calc(5px + 0.25vw);
  left: -5.5vw;
  background-color: var(--secondary-color-bluesky);
  height: 2px;
  width: 4.6vw;
}

/**  Menu Variant **/

.menu-variant {
  color: var(--secondary-color);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: fixed;
  margin: 2.5vw 1vw;
  height: 100vh;
  width: 2vw;
}

.menu-variant::after {
  content: "";
  position: absolute;
  top: -2.5vw;
  left: 3vw;
  width: 1px;
  height: 100%;
  background-color: var(--secondary-color);
}

.bgrd_variant {
  color: var(--secondary-color);
  font-family: var(--main-font);
  font-size: calc(10px + 1vw);
  text-decoration: underline;
}

.bgrd:hover {
  text-decoration: underline;
}

.menu-variant p {
  color: var(--secondary-color);
  font-family: var(--secondary-font);
  font-size: calc(10px + 0.5vw);
  position: absolute;
  top: 45%;
  transform: rotate(-90deg);
}

.menu-variant img {
  margin-bottom: 4.5vw;
  cursor: pointer;
}

.white-font {
  color: var(--main-color-white);
}

.menu-variant .white-font {
  color: var(--main-color-white);
}

.white-style {
  background-color: rgba(255, 255, 255, 0) !important;
  color: var(--main-color-white);
}

.white-style::after {
  content: "";
  position: absolute;
  top: -2.5vw;
  left: 3vw;
  width: 1px;
  height: 100%;
  background-color: var(--main-color-white);
}

/** Footer **/

footer {
  z-index: 1;
  font-family: var(--secondary-font);
  font-size: calc(5px + 0.5vw);
  display: flex;
  position: fixed;
  top: 49%;
  right: 0vw;
  gap: 1vw;
  transform: rotate(90deg);
}
footer a:hover {
  text-decoration: underline;
}
.arrow-moved-up {
  cursor: pointer;
  height: calc(30px + 1vw);
  z-index: 2;
  position: fixed;
  bottom: 4.3vh;
  right: 4vw;
}

/** Home **/

.home-content {
  overflow: hidden;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: var(--main-font);
  font-size: 5vw;
  color: var(--main-color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-content h2 {
  margin: -1.2vw;
}

.dev {
  margin-left: -4vw !important;
}

.web {
  margin-left: 10.35vw !important;
}

.design {
  margin-left: 11.1vw !important;
}

.name {
  margin-left: 15.05vw;
  margin-top: 0.3vw;
  font-size: 2.03vw;
  color: var(--secondary-color-bluesky);
}

.background-lines {
  z-index: -1;
  transform: scale(1.13);
  width: 55%;
  object-fit: cover;
  position: absolute;
  top: calc(50% - 2.8vw);
  right: calc(50% - 8vw);
}

/** Présentation **/

.tag-title {
  position: absolute;
  margin-left: 20vw;
  width: calc(70% + 8.5vw);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--main-font);
  font-size: calc(10px + 1.5vw);
  color: var(--main-color-white);
  text-indent: -12vw;
}

.container-arrow {
  z-index: 1;
  position: absolute;
  left: 50%;
  bottom: calc(25% - 3vw);
  height: calc(20px + 15vh);
}

.scrolling-down {
  animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.introduction-page {
  height: 100vh;
}

/** Section **/

section {
  height: 100vh;
  background-color: var(--main-color-white);
  display: flex;
}

.content-section {
  display: grid;
  width: 75vw;
  margin: auto;
  font-family: var(--secondary-font);
  color: var(--secondary-color);
}

.content-section h2 {
  font-size: calc(10px + 1.5vw);
  margin-bottom: 2vw;
  font-weight: 500;
}

.content-section p {
  font-size: calc(5px + 1vw);
}

section span {
  display: flex;
  width: 100%;
  height: 2px;
  background-color: var(--secondary-color);
  margin-top: 20vh;
}

.skills-tools {
  justify-self: center;
  margin-top: 5vw;
  width: calc(200px + 45vw);
}

/** Projets **/

.work-in-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10vw;
  background-color: var(--main-color-white);
  color: var(--secondary-color);
  font-family: var(--main-font);
  font-size: calc(15px + 1vw);
  height: 20vw;
  text-indent: -8vw;
  gap: 3vw;
}

.work-in-progress img {
  height: 5vw;
}

/** Projet **/

.projet {
  display: flex;
  gap: 2vw;
  position: relative;
}

.projet span {
  bottom: -15vh;
  justify-items: flex-end;
  position: absolute;
  display: flex;
  width: 100%;
  height: 2px;
  background-color: var(--secondary-color);
}

.content-projet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-projet h3 {
  font-size: calc(10px + 1vw);
  font-weight: 500;
  margin-bottom: 1.5vw;
}

.content-projet a {
  justify-content: flex-end;
  color: var(--secondary-color);
  text-decoration: underline;
  font-size: calc(7px + 0.5vw);
  font-weight: 500;
}

/** Carousel **/

.carousel {
  display: flex;
  position: relative;
}

.carousel_pictures {
  border: solid 1px var(--secondary-color);
  width: calc(200px + 30vw);
  object-fit: cover;
}

.carousel_nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftArrow {
  position: absolute;
  left: 0;
  height: calc(15px + 1.5vw);
}

.rightArrow {
  right: 0;
  position: absolute;
  height: calc(15px + 1.5vw);
}

.dots {
  position: absolute;
  bottom: -30px;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dot {
  margin-inline: 5px;
  width: 10px;
  height: 10px;
  border: 2px solid var(--secondary-color);
  border-radius: 100%;
}
.dot_selected {
  background-color: var(--secondary-color);
}

/** Contact **/

.tag-title-contact {
  position: absolute;
  margin-left: 10vw;
  width: calc(75% + 10vw);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--main-font);
  font-size: calc(10px + 1.5vw);
  color: var(--main-color-white);
}
.second-indent {
  padding-left: 4vw;
}
.third-indent {
  padding-left: 14vw;
}
form,
textarea {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vh);
  font-family: var(--secondary-font);
  border: none;
  height: 30vh;
  resize: none;
  outline: none;
}

form input {
  all: inherit;
  height: calc(25px + 5vh);
  border-bottom: 1px solid var(--secondary-color);
}

form textarea {
  border-bottom: 1px solid var(--secondary-color);
}

#message {
  margin-top: 1.5vh;
  width: calc(100% - 4px);
  height: 20vh;
  border-bottom: 2px solid var(--secondary-color);
}

button {
  color: var(--main-color-white);
  background-color: var(--secondary-color);
  font-family: var(--secondary-font);
  font-size: calc(10px + 0.5vw);
  height: calc(25px + 2vw);
  width: calc(45px + 4.5vw);
  border: none;
  place-self: flex-end;
}

/** Menu Page **/

.menu-page {
  z-index: 1;
  font-family: var(--main-font);
  font-size: calc(15px + 2.5vw);
  position: fixed;
  left: 9.5vw;
}

.menu-page-container {
  margin-top: -5.5vw;
  font-family: var(--secondary-font);
  gap: 1vw;
  position: fixed;
  top: 49%;
  display: flex;
  flex-direction: column;
}

.active-menu-page {
  color: var(--secondary-color-bluesky);
}

.active-menu-page::before {
  content: "";
  position: absolute;
  margin-top: calc(10px + 1.25vw);
  left: -5.5vw;
  background-color: var(--secondary-color-bluesky);
  height: 2px;
  width: 4.6vw;
}

/** PAGE GALERIE **/

.gallery-page {
  display: flex;
}
.gallery-content {
  z-index: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 33vw;
  background-color: var(--secondary-color);
  box-shadow: -5px 0px 15px #00000050;
}
.gallery-content h2 {
  color: var(--main-color-white);
  align-self: flex-start;
  font-family: var(--main-font);
  margin-left: 3vw;
  font-size: 50px;
}
.gallery-list {
  height: 70vh;
  overflow: scroll;
  scrollbar-width: none;
}
.gallery-list::-webkit-scrollbar {
  display: none;
}
.gallery-projet {
  display: flex;
  justify-content: space-between;
  width: 25vw;
  color: var(--main-color-white);
  font-family: var(--secondary-font);
  margin-top: 5vh;
}
.gallery-projet h3 {
  font-size: calc(10px + 1.5vw);
  font-weight: 500;
  margin-bottom: 5px;
  cursor: pointer;
}
.active-projet {
  color: var(--secondary-color-bluesky);
}
.active-projet::before {
  content: "";
  position: absolute;
  margin-top: calc(4px + 0.1vw);
  margin-left: -2.2vw;
  background-color: var(--secondary-color-bluesky);
  height: 1.5vw;
  width: 1.5vw;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
}
.gallery-projet h3:hover {
  transition: 0.2s;
  color: var(--secondary-color-bluesky);
}
.gallery-projet p {
  font-size: calc(10px + 0.5vw);
}
.link-projet {
  display: flex;
  font-size: calc(5px + 0.5vw);
  align-items: flex-end;
}
.link-projet span {
  margin-right: 10px;
}
.container-iframe img {
  margin-right: -20px;
  display: block;
  object-fit: contain;
  width: 70vw;
  height: 100vh;
}
.iFrame {
  display: block;
  margin-right: -20px;
  height: 100vh;
  width: 70vw;
  background-color: var(--main-color-white);
}
.tag-iframe {
  background-color: var(--secondary-color);
  color: white;
  font-family: var(--main-font);
  font-size: calc(10px + 1.5vw);
  padding: 0.8vw 2vw 0.4vw 2vw;
  top: 5%;
  position: absolute;
  animation: slide 1s forwards;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/** RESPONSIVE **/

@media (max-width: 768px) {
  .projet {
    flex-direction: column;
  }
  .content-section {
    width: 85vw;
  }
  .content-projet a {
    margin-top: 2vw;
  }
  .carousel_pictures {
    width: 100%;
  }
  .rightArrow {
    right: -1px;
    height: 38px;
  }
  .leftArrow {
    height: 38px;
  }
  .link-projet span {
    display: none;
  }
}

@media (max-width: 425px) {
  section span {
    opacity: 0;
  }
  .menu-standard {
    font-size: calc(15px + 4.5vw);
    margin-top: 10vw;
  }
  .menu {
    width: 90vw;
    font-size: calc(10px + 1.5vw);
    justify-content: space-around;
    flex-direction: row;
    top: 15vh;
  }
  .menu a {
    padding: 5px;
  }
  .active {
    border: solid 1px var(--secondary-color-bluesky);
  }
  .active::before {
    content: none;
  }
  .menu-variant {
    z-index: 2;
    background-color: white;
    gap: 30vw;
    margin: 0;
    height: 10vh;
    width: 100vw;
    flex-direction: row;
    justify-content: space-around;
  }
  .menu-variant p {
    margin-top: -5px;
    font-size: calc(10px + 3.5vw);
    transform: none;
  }
  .menu-variant img {
    margin-bottom: 0;
  }
  .bgrd_variant {
    font-size: calc(10px + 3vw);
  }
  .menu-variant::after {
    top: 10vh;
    left: 0;
    width: 100%;
    height: 1px;
  }
  .menu-page-container {
    top: 40%;
    gap: 5vw;
  }
  .tag-title {
    margin-left: 10vw;
    font-size: calc(10px + 5vw);
    text-indent: 0;
  }
  .home-content {
    font-size: 10vw;
  }
  .dev {
    margin-left: -13.7vw !important;
  }
  .web {
    margin-left: 15vw !important;
  }
  .design {
    margin-left: 16.5vw !important;
  }
  .name {
    font-size: 5vw;
    margin-left: 19.5vw;
  }
  .background-lines {
    transform: scale(2.5);
    top: calc(50% - -27vw);
    right: calc(50% - -19vw);
  }
  .container-arrow {
    bottom: calc(25% - 10vw);
  }
  .tag-title-contact {
    font-size: calc(10px + 5vw);
  }
  .second-indent {
    padding-left: 0;
  }
  .third-indent {
    padding-left: 0;
  }
  .icon-galerie {
    display: none;
  }
  .adjust-inspiration {
    height: 40vh;
  }
  .adjust-skills,
  .adjust-about {
    height: 70vh;
  }
  .content-section h2 {
    font-size: calc(10px + 3.5vw);
    margin-bottom: 5vh;
  }
  .content-section p {
    margin-bottom: 3vh;
    font-size: calc(5px + 3.5vw);
  }
  .content-projet h3 {
    font-size: calc(10px + 5vw);
    margin-bottom: 2vh;
  }
  .content-projet a {
    margin-bottom: 1vh;
    font-size: 5vw;
  }
  .work-in-progress {
    display: flex;
    flex-direction: column;
    height: 40vw;
    font-size: calc(15px + 2vw);
  }
  .work-in-progress img {
    height: 10vw;
  }
  .active-menu-page::before {
    left: -10vw;
    width: 7vw;
  }
  footer {
    gap: 5vw;
    right: 22%;
    top: 85vh;
    transform: none;
    font-size: 4.5vw;
  }
  .skills-tools {
    width: 100%;
    height: 250px;
  }
  button {
    transform-origin: top right;
    transform: scale(1.5);
  }
}
